import { Dispatch, SetStateAction, createContext } from "react";

export type ChatUsage = {
  used: number;
  available: number;
};

export const UsageContext = createContext<{
  usage?: ChatUsage;
  setUsage: Dispatch<SetStateAction<ChatUsage | undefined>>;
}>({
  usage: undefined,
  setUsage: () => {},
});
