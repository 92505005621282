import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { ArrowUpRightSquare, Mail } from "lucide-react";
import mixpanel from "mixpanel-browser";
import Link from "next/link";
import { FC } from "react";

type Props = {
  show: boolean;
  onOpenChange: (open: boolean) => void;
};

const SupportModal: FC<Props> = ({ show, onOpenChange }) => {
  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent className="flex flex-col items-center md:flex-row p-0 max-w-sm">
        <DialogHeader className="p-6">
          <DialogTitle className="pb-3 text-xl">Support</DialogTitle>
          <DialogDescription className="text-sm max-w-xs text-slate-600 flex flex-col items-center text-left w-full gap-6">
            <div className="w-full">
              <h3 className="text-xs text-neutral-4 font-semibold pb-1">
                Discord
              </h3>
              <a
                href="https://discord.gg/rRBuNgKUdz"
                target="_blank"
                className="w-min -ml-1 px-2 py-1 flex items-center gap-2 rounded-lg font-semibold text-primary-10 hover:bg-neutral-1"
                onClick={() => mixpanel.track("Clicked discord link")}
              >
                <span className="whitespace-nowrap text-sm">
                  Join our Discord
                </span>
                <ArrowUpRightSquare strokeWidth={2} className="w-4 h-4" />
              </a>
            </div>
            <div className="w-full">
              <h3 className="text-xs text-neutral-4 font-semibold pb-1">
                Feedback
              </h3>
              <a
                href="https://forms.gle/W4quL45zCaxf3yNR7"
                target="_blank"
                onClick={() => {
                  mixpanel.track("Opened feedback form");
                }}
                className="w-min -ml-1 px-2 py-1 flex items-center gap-2 rounded-lg font-semibold text-primary-10 hover:bg-neutral-1"
              >
                <span className="whitespace-nowrap text-sm">
                  Tell us what you think
                </span>
                <ArrowUpRightSquare strokeWidth={2} className="w-4 h-4" />
              </a>
            </div>
            <div className="w-full">
              <h3 className="text-xs text-neutral-4 font-semibold pb-1">
                Contact details
              </h3>
              <div className="font-normal py-1 pl-1 text-primary-10">
                <div className="flex items-center">
                  <Mail className="w-4 h-4 mr-2" />
                  <span>hello@stellaai.com.au</span>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default SupportModal;
