"use client";

import { FC, PropsWithChildren } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="hidden md:block">
        <Sidebar />
      </div>
      <Navbar />
      <div className="flex flex-col w-full h-full overflow-y-auto">
        <div className="flex flex-col h-full md:flex-row">{children}</div>
      </div>
    </>
  );
};

export default Layout;
