import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { FC } from "react";
import OnboardingForm from "./OnboardingForm";

type Props = {
  show: boolean;
  onOpenChange: (open: boolean) => void;
  fullName: string;
  userId: string;
  userEmail: string;
};

const OnboardingModal: FC<Props> = ({
  show,
  onOpenChange,
  fullName,
  userId,
  userEmail,
}) => {
  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent
        className="flex flex-col md:flex-row p-0 max-w-2xl gap-0"
        allowManualClose={false}
      >
        <div className="rounded-l-lg bg-slate-100 py-10 px-8">
          <DialogTitle className="pb-2 text-2xl whitespace-nowrap">
            Welcome to Stella AI!
          </DialogTitle>
          <p className="text-neutral-6 text-sm">
            Let&apos;s set up your profile.
          </p>
        </div>
        <DialogHeader className="py-10 px-8 w-full flex items-center">
          <DialogDescription className="text-sm max-w-xs text-slate-600 flex flex-col items-center text-left w-full gap-6">
            <OnboardingForm
              fullName={fullName}
              onClose={() => onOpenChange(false)}
              userId={userId}
              userEmail={userEmail}
            />
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingModal;
