"use client";

import { Database } from "@/types/supabase";
import { ALL_HSC_SUBJECTS } from "@/utils/consts";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import * as Sentry from "@sentry/nextjs";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import classNames from "classnames";
import {
  Check,
  ChevronsUpDown,
  CircleUser,
  GraduationCap,
  Library,
  Mail,
  School,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import Button from "../base/Button";

type Props = {
  name?: string;
  email?: string;
  school?: string;
  graduationYear?: number;
  subjects?: string[];
  userID: string;
};

const graduationYears = [2023, 2024, 2025, 2026];

const AccountForm: FC<Props> = ({
  name,
  email,
  school,
  graduationYear,
  subjects,
  userID,
}) => {
  const [newName, setNewName] = useState(name);
  const [newSchool, setNewSchool] = useState(school);
  const [newGraduationYear, setNewGraduationYear] = useState(graduationYear);
  const [newSubjects, setNewSubjects] = useState(subjects);
  const [changesMade, setChangesMade] = useState(false);
  const [query, setQuery] = useState("");

  const supabase = createClientComponentClient<Database>();

  const saveChanges = async () => {
    setChangesMade(false);

    const { error } = await supabase
      .from("profiles")
      .update({
        full_name: newName,
        school: newSchool,
        graduation_year: newGraduationYear,
        subjects: newSubjects,
      })
      .eq("id", userID);

    if (error) {
      toast.error("Failed to update profile");
      Sentry.captureException("Error when saving profile in modal: " + error);
    } else {
      toast.success("Profile updated");
      mixpanel.track("Updated profile");
    }
  };

  const filteredSubjects =
    query === ""
      ? ALL_HSC_SUBJECTS
      : ALL_HSC_SUBJECTS.filter((subject) =>
          subject
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="email"
          className="block mb-2 text-start text-xs text-neutral-4 font-semibold"
        >
          Email
        </label>
        <div className="flex">
          <span className="inline-flex items-center px-3 text-sm text-neutral-2 bg-primary-10 border rounded-e-0 border-primary-10/50 rounded-s-md">
            <Mail className="w-5 h-5" />
          </span>
          <input
            type="email"
            id="email"
            className="disabled:cursor-not-allowed disabled:text-gray-900/90 rounded-none rounded-e-lg disabled:bg-gray-50 border text-gray-900 block focus:outline-0 flex-1 min-w-0 w-full text-sm border-l-0 border-primary-10/50 p-2.5"
            value={email}
            disabled
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="name"
          className="block text-start mb-2 text-xs text-neutral-4 font-semibold"
        >
          Name
        </label>
        <div className="flex">
          <span className="inline-flex items-center px-3 text-sm text-neutral-2 bg-primary-10 border rounded-e-0 border-primary-10/50 rounded-s-md">
            <CircleUser className="w-5 h-5" />
          </span>
          <input
            type="text"
            id="name"
            className="rounded-none rounded-e-lg bg-white border text-gray-900 block focus:outline-0 flex-1 min-w-0 w-full text-sm border-l-0 border-primary-10/50 p-2.5"
            placeholder="Ada Lovelace"
            value={newName}
            onChange={(e) => {
              setChangesMade(true);
              setNewName(e.target.value);
            }}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="school"
          className="block text-start mb-2 text-xs text-neutral-4 font-semibold"
        >
          School
        </label>
        <div className="flex">
          <span className="inline-flex items-center px-3 text-sm text-neutral-2 bg-primary-10 border rounded-e-0 border-primary-10/50 rounded-s-md">
            <School className="w-5 h-5" />
          </span>
          <input
            type="text"
            id="school"
            className="rounded-none placeholder:text-gray-900/30 rounded-e-lg bg-white border text-gray-900 block focus:outline-0 flex-1 min-w-0 w-full text-sm border-l-0 border-primary-10/50 p-2.5"
            placeholder="Stella High School"
            value={newSchool}
            onChange={(e) => {
              setChangesMade(true);
              setNewSchool(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-6">
        <div>
          <label
            htmlFor="graduation-year"
            className="block text-start mb-2 text-xs text-neutral-4 font-semibold"
          >
            Graduation Year
          </label>
          <div className="flex">
            <span className="inline-flex items-center px-3 text-sm text-neutral-2 bg-primary-10 border rounded-e-0 border-primary-10/50 rounded-s-md">
              <GraduationCap className="w-5 h-5" />
            </span>
            <Listbox
              value={newGraduationYear}
              onChange={(value) => {
                setChangesMade(true);
                setNewGraduationYear(value);
              }}
            >
              <div className="relative">
                <Listbox.Button className="bg-white border text-gray-900 block focus:outline-0 flex-1 min-w-0 w-full text-sm border-l-0 border-primary-10/50 p-2.5 relative rounded-r-lg py-2 pl-3 pr-10 text-left cursor-pointer">
                  {newGraduationYear ? (
                    <span className="block truncate text-gray-900">
                      {newGraduationYear}
                    </span>
                  ) : (
                    <span className="block truncate text-gray-900/30">
                      2024
                    </span>
                  )}
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronsUpDown className="h-4 w-4" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                    {graduationYears.map((year) => (
                      <Listbox.Option
                        key={year}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-primary-1/50 text-primary-8"
                              : "text-neutral-8"
                          }`
                        }
                        value={year}
                      >
                        {({ selected }) => (
                          <div className="flex items-center justify-between">
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {year}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-7">
                                <Check className="h-4 w-4" aria-hidden="true" />
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
        <div>
          <label
            htmlFor="graduation-year"
            className="block text-start mb-2 text-xs text-neutral-4 font-semibold"
          >
            Current Subjects
          </label>
          <div className="flex">
            <span className="inline-flex items-center px-3 text-sm text-neutral-2 bg-primary-10 border rounded-e-0 border-primary-10/50 rounded-s-md">
              <Library className="w-5 h-5" />
            </span>
            <Combobox
              value={newSubjects}
              onChange={(value) => {
                setChangesMade(true);
                setNewSubjects(value);
              }}
              multiple={true as any}
            >
              <div className="relative w-64">
                <div className="bg-white border text-gray-900 block focus:outline-0 flex-1 min-w-0 w-full text-sm border-l-0 border-primary-10/50 relative rounded-r-lg text-left cursor-pointer">
                  <Combobox.Input
                    className={classNames(
                      "w-full border-none py-2 pl-3 pr-10 leading-5 focus:ring-0 rounded-none rounded-e-lg bg-white border text-gray-900 block focus:outline-0 flex-1 min-w-0 text-sm border-l-0 border-primary-10/50 p-2.5",
                      newSubjects && newSubjects.length > 0
                        ? "placeholder:text-gray-900"
                        : "placeholder:text-gray-900/30"
                    )}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={
                      newSubjects && newSubjects.length > 0
                        ? `${newSubjects.length} subjects selected`
                        : "No subjects selected"
                    }
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronsUpDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                    {filteredSubjects.length === 0 && query !== "" ? (
                      <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredSubjects.map((subject) => (
                        <Combobox.Option
                          key={subject}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-primary-1/50 text-primary-8"
                                : "text-neutral-8"
                            }`
                          }
                          value={subject}
                        >
                          {({ selected }) => (
                            <div className="flex items-center justify-between">
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {subject}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-7">
                                  <Check
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
        </div>
      </div>

      <div className="flex justify-end pt-6">
        <Button
          size="md"
          text="Save"
          variant="primary"
          onClick={saveChanges}
          disabled={!changesMade}
        />
      </div>
    </div>
  );
};

export default AccountForm;
