"use client";

import { portal } from "@/app/actions";
import OnboardingModal from "@/components/modal/OnboardingModal/OnboardingModal";
import ProfileModal from "@/components/modal/ProfileModal";
import SupportModal from "@/components/modal/SupportModal";
import UpgradeModal from "@/components/modal/UpgradeModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  CreditCard,
  ExternalLink,
  GraduationCap,
  HelpCircle,
  PanelLeftOpen,
  User,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { FC, useEffect, useState } from "react";
import { useSessionContext } from "@/context/useSessionContext";
import Sidebar from "./Sidebar";
import { hasFullAccess } from "@/utils/helpers";

const Navbar: FC = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const { profile } = useSessionContext();

  useEffect(() => {
    if (profile && !profile.origin) {
      setShowOnboardingModal(true);
    }
  }, [profile]);

  return (
    <>
      <nav className="absolute top-0 z-30 flex items-center justify-between w-full h-14 px-4 md:px-8 shrink-0 bg-white/5 sm:bg-transparent border-slate-100 backdrop-blur-sm sm:backdrop-blur-none">
        <Sheet>
          <SheetTrigger className="visible cursor-pointer md:invisible">
            <PanelLeftOpen className="text-primary-10" strokeWidth={1.6} />
          </SheetTrigger>
          <SheetContent side="left">
            <Sidebar full />
          </SheetContent>
        </Sheet>

        {profile && (
          <div className="flex gap-5 items-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="focus:outline-0">
                <div className="flex items-center justify-center w-8 h-8 uppercase bg-white border border-primary-10/50 rounded-full">
                  {profile.full_name ? (
                    <span>{profile.full_name[0]}</span>
                  ) : (
                    <User className="w-3.5 h-3.5" strokeWidth={2.6} />
                  )}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-44" align="end">
                <DropdownMenuItem
                  onClick={() => {
                    setShowProfileModal(true);
                    mixpanel.track("Opened profile modal");
                  }}
                >
                  <div className="flex items-center">
                    <User className="w-3.5 h-3.5 mr-2" strokeWidth={2.6} />
                    <span>Profile</span>
                  </div>
                </DropdownMenuItem>
                {hasFullAccess(profile) ? (
                  <DropdownMenuItem
                    onClick={() => {
                      mixpanel.track("Navigated to stripe portal");
                      portal();
                    }}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <CreditCard
                          className="w-3.5 h-3.5 mr-2"
                          strokeWidth={2.6}
                        />
                        <span>Manage Plan</span>
                      </div>
                      <ExternalLink
                        className="w-3.5 h-3.5 mr-2 text-slate-400"
                        strokeWidth={2}
                      />
                    </div>
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    onClick={() => {
                      setShowUpgradeModal(true);
                      mixpanel.track("Opened upgrade modal");
                    }}
                  >
                    <div className="flex items-center">
                      <GraduationCap
                        className="w-3.5 h-3.5 mr-2"
                        strokeWidth={2.6}
                      />
                      <span>Start Your Plan</span>
                    </div>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    setShowSupportModal(true);
                    mixpanel.track("Opened support modal");
                  }}
                >
                  <div className="flex items-center">
                    <HelpCircle
                      className="w-3.5 h-3.5 mr-2"
                      strokeWidth={2.6}
                    />
                    <span>Support</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </nav>
      {profile && (
        <>
          <ProfileModal
            profile={profile}
            show={showProfileModal}
            onOpenChange={setShowProfileModal}
          />
          <UpgradeModal
            show={showUpgradeModal}
            onOpenChange={setShowUpgradeModal}
            paywalled={false}
          />
          <SupportModal
            show={showSupportModal}
            onOpenChange={setShowSupportModal}
          />
          <OnboardingModal
            userId={profile.id}
            userEmail={profile.email ?? ""}
            fullName={profile.full_name ?? ""}
            show={showOnboardingModal}
            onOpenChange={(value) => {
              setShowUpgradeModal(true);
              setShowOnboardingModal(value);
            }}
          />
        </>
      )}
    </>
  );
};

export default Navbar;
