import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Database } from "@/types/supabase";
import { FC } from "react";
import AccountForm from "../profile/AccountForm";

type Props = {
  profile: Database["public"]["Tables"]["profiles"]["Row"];
  show: boolean;
  onOpenChange: (open: boolean) => void;
};

const ProfileModal: FC<Props> = ({ profile, show, onOpenChange }) => {
  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="pb-6">Edit Profile</DialogTitle>
          <AccountForm
            name={profile.full_name ?? undefined}
            email={profile.email ?? undefined}
            userID={profile.id}
            school={profile.school ?? undefined}
            graduationYear={profile.graduation_year ?? undefined}
            subjects={profile.subjects ?? undefined}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileModal;
