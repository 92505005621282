"use client";

import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC, ReactNode } from "react";

type Props = {
  text: string;
  href: string;
  icon: ReactNode;
  onClick?: () => void;
};

const NavLink: FC<Props> = ({ text, href, icon, onClick }) => {
  const pathname = usePathname();

  return (
    <Link
      {...(href.startsWith("http") && { target: "_blank" })}
      href={href}
      onClick={() => {
        mixpanel.track(`Navigated to ${href}`);
        onClick?.();
      }}
      className="w-full px-2"
    >
      <div
        className={classNames(
          "w-full px-4 py-3 flex items-center gap-2 rounded-lg",
          pathname === href && "bg-white/10"
        )}
      >
        {icon}
        <span className="whitespace-nowrap font-normal text-sm">{text}</span>
      </div>
    </Link>
  );
};

export default NavLink;
