"use client";

import { ChatUsage, UsageContext } from "@/context/UsageContext";
import type { Database } from "@/types/supabase";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Auth } from "@supabase/auth-ui-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/navigation";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getChatUsage } from "./helpers";
import SessionContextProvider from "@/context/SessionContext";
import { hasFullAccess } from "./helpers";

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const [supabase] = useState(() => createClientComponentClient<Database>());
  const [queryClient] = useState(() => new QueryClient());
  const searchParams = useMemo(() => {
    return new URLSearchParams(
      typeof window !== "undefined" ? window.location.search : ""
    );
  }, []);
  const router = useRouter();
  const [usage, setUsage] = useState<ChatUsage | undefined>();

  useEffect(() => {
    async function initiateSession() {
      mixpanel.init("1b537db9bf875f07f3cf08751710f199", {
        track_pageview: true,
        persistence: "localStorage",
      });

      const { data } = await supabase.auth.getSession();
      const { session } = data;

      if (!session) return;

      const usage = await getChatUsage({ supabase });
      setUsage(usage);

      mixpanel.identify(session.user.id);
      mixpanel.people.set({
        $email: session.user.email,
        $name: session.user.user_metadata.full_name,
        $avatar: session.user.user_metadata.avatar_url,
        created_at: session.user.created_at,
      });
    }

    initiateSession();
  }, [supabase]);

  useEffect(() => {
    const refetchUserSubscriptionStatus = async () => {
      for (let i = 0; i < 10; i++) {
        const { data } = await supabase.from("profiles").select("*").single();

        if (hasFullAccess(data ?? undefined)) {
          mixpanel.track("Upgrade success");
          toast.success("Upgrade successful");
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    };
    const success = searchParams.get("success");
    if (success) {
      refetchUserSubscriptionStatus();
      router.replace("/");
    }
  }, [supabase, searchParams, router]);

  return (
    <Auth.UserContextProvider supabaseClient={supabase}>
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider>
          <UsageContext.Provider
            value={{
              usage,
              setUsage,
            }}
          >
            {children}
          </UsageContext.Provider>
        </SessionContextProvider>
      </QueryClientProvider>
    </Auth.UserContextProvider>
  );
};

export default Provider;
