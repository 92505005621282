"use client";

import UpgradeModal from "@/components/modal/UpgradeModal";
import classNames from "classnames";
import { GraduationCap, History, MessageSquareDashed } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { FC, useState } from "react";
import Button from "./Button";
import NavLink from "./NavLink";
import { useSessionContext } from "@/context/useSessionContext";
import { hasFullAccess } from "@/utils/helpers";

type Props = {
  full?: boolean;
};

const Sidebar: FC<Props> = ({ full = false }) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { profile } = useSessionContext();

  return (
    <>
      <div
        className={classNames(
          "bg-neutral-10 h-screen px-1 flex flex-col justify-between items-center text-neutral-3",
          full ? "w-full pb-5" : "w-56 pb-5"
        )}
      >
        <div className="flex flex-col w-full gap-1 pt-14">
          <NavLink
            href="/"
            icon={
              <MessageSquareDashed
                strokeWidth={2}
                className="w-5 h-5 shrink-0"
              />
            }
            text="Ask Question"
          />
          <NavLink
            href="/history"
            icon={<History strokeWidth={2} className="w-5 h-5 shrink-0" />}
            text="History"
          />
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          {profile && !hasFullAccess(profile) && (
            <div className="w-full px-3 py-3">
              <Button
                icon={
                  <GraduationCap
                    strokeWidth={2.4}
                    className="w-4 h-4 shrink-0"
                  />
                }
                size="md"
                text="Start Your Plan"
                variant="primary"
                onClick={() => {
                  setShowUpgradeModal(true);
                  mixpanel.track("Opened upgrade modal");
                }}
                full
              />
            </div>
          )}
          <a
            href="https://discord.gg/rRBuNgKUdz"
            className="flex gap-2 items-center hover:opacity-80"
            target="_blank"
            onClick={() => mixpanel.track("Clicked discord link")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="18"
              height="18"
              viewBox="0,0,256,256"
              className="opacity-80"
            >
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                stroke="none"
                strokeWidth={1}
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit={10}
                strokeDasharray=""
                strokeDashoffset={0}
                fontFamily="none"
                fontWeight="none"
                fontSize="none"
                textAnchor="none"
              >
                <g transform="scale(5.12,5.12)">
                  <path d="M41.625,10.76953c-3.98047,-3.20313 -10.27734,-3.74609 -10.54687,-3.76563c-0.41797,-0.03516 -0.81641,0.19922 -0.98828,0.58594c-0.01562,0.02344 -0.15234,0.33984 -0.30469,0.83203c2.63281,0.44531 5.86719,1.33984 8.79297,3.15625c0.46875,0.28906 0.61328,0.90625 0.32422,1.375c-0.19141,0.30859 -0.51562,0.47656 -0.85156,0.47656c-0.17969,0 -0.36328,-0.05078 -0.52734,-0.15234c-5.03125,-3.12109 -11.3125,-3.27734 -12.52344,-3.27734c-1.21094,0 -7.49609,0.15625 -12.52344,3.27734c-0.46875,0.29297 -1.08594,0.14844 -1.375,-0.32031c-0.29297,-0.47266 -0.14844,-1.08594 0.32031,-1.37891c2.92578,-1.8125 6.16016,-2.71094 8.79297,-3.15234c-0.15234,-0.49609 -0.28906,-0.80859 -0.30078,-0.83594c-0.17578,-0.38672 -0.57031,-0.62891 -0.99219,-0.58594c-0.26953,0.01953 -6.56641,0.5625 -10.60156,3.80859c-2.10547,1.94922 -6.32031,13.33984 -6.32031,23.1875c0,0.17578 0.04688,0.34375 0.13281,0.49609c2.90625,5.10938 10.83984,6.44531 12.64844,6.50391c0.00781,0 0.01953,0 0.03125,0c0.32031,0 0.62109,-0.15234 0.80859,-0.41016l1.82813,-2.51562c-4.93359,-1.27344 -7.45312,-3.4375 -7.59766,-3.56641c-0.41406,-0.36328 -0.45312,-0.99609 -0.08594,-1.41016c0.36328,-0.41406 0.99609,-0.45312 1.41016,-0.08984c0.05859,0.05469 4.69922,3.99219 13.82422,3.99219c9.14063,0 13.78125,-3.95312 13.82813,-3.99219c0.41406,-0.35937 1.04297,-0.32422 1.41016,0.09375c0.36328,0.41406 0.32422,1.04297 -0.08984,1.40625c-0.14453,0.12891 -2.66406,2.29297 -7.59766,3.56641l1.82813,2.51563c0.1875,0.25781 0.48828,0.41016 0.80859,0.41016c0.01172,0 0.02344,0 0.03125,0c1.80859,-0.05859 9.74219,-1.39453 12.64844,-6.50391c0.08594,-0.15234 0.13281,-0.32031 0.13281,-0.49609c0,-9.84766 -4.21484,-21.23828 -6.375,-23.23047zM18.5,30c-1.93359,0 -3.5,-1.78906 -3.5,-4c0,-2.21094 1.56641,-4 3.5,-4c1.93359,0 3.5,1.78906 3.5,4c0,2.21094 -1.56641,4 -3.5,4zM31.5,30c-1.93359,0 -3.5,-1.78906 -3.5,-4c0,-2.21094 1.56641,-4 3.5,-4c1.93359,0 3.5,1.78906 3.5,4c0,2.21094 -1.56641,4 -3.5,4z"></path>
                </g>
              </g>
            </svg>
            <span className="font-bold text-xs">Join our Discord</span>
          </a>
        </div>
      </div>

      <UpgradeModal
        show={showUpgradeModal}
        onOpenChange={setShowUpgradeModal}
        paywalled={false}
      />
    </>
  );
};

export default Sidebar;
