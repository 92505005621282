"use client";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Database } from "@/types/supabase";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/nextjs";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import mixpanel from "mixpanel-browser";
import { FC } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  graduation: z.string(),
  origin: z.string(),
});

type Props = {
  fullName: string;
  onClose: () => void;
  userId: string;
  userEmail: string;
};

const OnboardingForm: FC<Props> = ({
  fullName,
  onClose,
  userId,
  userEmail,
}) => {
  const supabase = createClientComponentClient<Database>();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: fullName.split(" ")[0],
      lastName: fullName.split(" ")[1],
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { firstName, lastName, graduation, origin } = values;

    const [_, { error }] = await Promise.all([
      fetch("/api/addContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          firstName,
          lastName,
        }),
      }),
      supabase
        .from("profiles")
        .update({
          first_name: firstName,
          last_name: lastName,
          graduation_year: Number(graduation),
          origin,
        })
        .eq("id", userId),
    ]);

    if (error) {
      Sentry.captureException("Error when posting onboarding form: " + error);
    }

    onClose();
    mixpanel.track("Completed onboarding form");
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="graduation"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Graduation Year</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="2024">2024</SelectItem>
                  <SelectItem value="2025">2025</SelectItem>
                  <SelectItem value="2026">2026</SelectItem>
                  <SelectItem value="2027">2027</SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="origin"
          render={({ field }) => (
            <FormItem>
              <FormLabel>How did you hear about Stella AI?</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="friends">Friends</SelectItem>
                  <SelectItem value="google-search">Google Search</SelectItem>
                  <SelectItem value="discord">Discord</SelectItem>
                  <SelectItem value="facebook">Facebook</SelectItem>
                  <SelectItem value="tiktok">Tiktok</SelectItem>
                  <SelectItem value="bos">Bored of Studies</SelectItem>
                  <SelectItem value="other">Other</SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <div className="flex justify-end pt-5">
          <Button
            loading={form.formState.isSubmitting}
            disabled={form.formState.isSubmitting}
            type="submit"
          >
            Done
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default OnboardingForm;
